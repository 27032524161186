export default {
  pageTitle: 'Dashboard',
  welcome: 'Howzit',
  welcomeMessage: 'The Campfire is ready for you!',
  quickLinks: 'Quick Access',
  quickLinksItems: {
    serviceDesk: 'Service Desk',
    productDetails: 'Product Details',
    ESSPortal: 'ESS Portal',
    B2BCustomers: 'B2B Customers',
    Subscribers: 'Subscribers',
    WarehousePickingDashboard: 'Warehouse Picking Dashboard',
    campfirev1: 'go to Campfire v1',
    ResetApp: 'Reset App'
  },
  quickSettings: 'Quick Settings',
  recentUpdates: 'Recent Updates',
  viewAccount: 'View Account'
}

export default {
  header: 'Teken In',
  subheader: 'Slegs Front Runner personeel',
  emailLabel: 'Jou e-posadres',
  emailRequiredLabel: 'E-posadres is verpligtend',
  passwordLabel: 'Jou wagwoord',
  passwordRequiredLabel: 'Wagwoord is verpligtend',
  buttonLabel: 'Teken In',
  loginWithMicrosoftButtonLabel: 'Teken in met Microsoft'
}

export default {
  header: 'Connexion',
  subheader: 'Réservé au personnel de Front Runner',
  emailLabel: 'Votre adresse e-mail',
  emailRequiredLabel: "L'adresse e-mail est requise",
  passwordLabel: 'Votre mot de passe',
  passwordRequiredLabel: 'Le mot de passe est requis',
  buttonLabel: 'Connexion',
  loginWithMicrosoftButtonLabel: 'Se connecter avec Microsoft'
}

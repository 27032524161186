export default {
  pageTitle: 'Tableau de bord',
  welcome: 'Bienvenue',
  welcomeMessage: 'Le feu de camp est prêt pour vous!',
  quickLinks: 'Liens rapides',
  quickLinksItems: {
    serviceDesk: 'Service Desk',
    productDetails: 'Détails du produit',
    ESSPortal: 'Portail ESS',
    B2BCustomers: 'Clients B2B',
    Subscribers: 'Abonnés',
    WarehousePickingDashboard: 'Tableau de bord de préparation de commande',
    campfirev1: 'Campfire v1',
    ResetApp: "Réinitialiser l'application"
  },
  quickSettings: 'Paramètres rapides',
  recentUpdates: 'Mises à jour récentes',
  viewAccount: 'Voir le compte'
}

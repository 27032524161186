export default {
  pageTitle: 'Armaturenbrett',
  welcome: 'Willkommen',
  welcomeMessage: 'Das Lagerfeuer steht Ihnen zur Verfügung!',
  quickLinks: 'Schnellzugriffe',
  quickLinksItems: {
    serviceDesk: 'Serviceschalter',
    productDetails: 'Produktdetails',
    ESSPortal: 'ESS-Portal',
    B2BCustomers: 'B2B-Kunden',
    Subscribers: 'Abonnenten',
    WarehousePickingDashboard: 'Lager-Picking-Dashboard',
    ResetApp: 'App zurücksetzen'
  },
  quickSettings: 'Schnelleinstellungen',
  recentUpdates: 'Neueste Updates',
  viewAccount: 'Konto anzeigen'
}

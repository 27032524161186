import { type I18nType } from '~/i18n.config'

import enUs from './en-us'
import enZa from './en-za'
import deDe from './de-de'
import afZa from './af-za'
import frFr from './fr-fr'

export const dashboardMessages: I18nType = {
  'en-us': enUs,
  'en-za': enZa,
  'af-za': afZa,
  'de-de': deDe,
  'fr-fr': frFr
}

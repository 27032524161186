import { dashboardMessages } from '~/features/dashboard/i18n'
import { loginMessages } from '~/features/auth/login/i18n'
import { logoutMessages } from '~/features/auth/logout/i18n'
import { accountMessages } from '~/features/auth/account/i18n'

/**
 * Defines the i18n configuration object.
 * @returns The i18n configuration object.
 */
export default defineI18nConfig(() => {
  const languages: I18nType = {
    'en-us': {},
    'en-za': {},
    'de-de': {},
    'af-za': {},
    'fr-fr': {}
  }

  // sort languages and merge
  Object.keys(languages).forEach((key) => {
    languages[key] = {
      dashboard: dashboardMessages[key],
      login: loginMessages[key],
      logout: logoutMessages[key],
      account: accountMessages[key]
    }
  })

  return {
    legacy: false,
    locale: 'en-za',
    messages: {
      ...(languages as object)
    }
  }
})

export type I18nType = {
  [key: string]:
    | {
        [key: string]: { [key: string]: string } | string | undefined
      }
    | object
}

export default {
  pageTitle: 'Dashboard',
  welcome: 'Welkom',
  welcomeMessage: 'Die Kampvuur is gereed vir jou!',
  quickLinks: 'Vinnige skakels',
  quickLinksItems: {
    serviceDesk: 'Diensloket',
    productDetails: 'Produkbesonderhede',
    ESSPortal: 'ESS Portal',
    B2BCustomers: 'B2B Kliënte',
    Subscribers: 'Intekenare',
    WarehousePickingDashboard: 'Stoor Kies Dashboard',
    ResetApp: 'Herstel Toepassing'
  },
  quickSettings: 'Vinnige instellings',
  recentUpdates: 'Onlangse opdaterings',
  viewAccount: 'Besigtig Rekening'
}
